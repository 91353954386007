import Home from "./Homepage"
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Launch from "./launchpage"
import {useEffect,useState } from "react" 
import styled from "@emotion/styled"
import {green} from "@mui/material/colors"

function App() {
  
  return (
    <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
        </Switch>
      </BrowserRouter>
   
  );
}

export default App;
