
import Header from "./header"
import {  useState, useRef, useEffect } from "react"

import {  ThemeProvider, Typography, Grid, Card, CardMedia, CardContent, Button, InputBase, } from "@mui/material"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { theme } from "./styles"

import { motion, useInView, useAnimation, AnimatePresence } from "framer-motion";

import heroBanner from "./assets/Hero-Banner_2.jpg"
import hero from "./assets/Heroimage.jpg"
import heroImage1 from "./assets/Hero-Banner_BG_1.jpg"
import heroImage2 from "./assets/Hero-Banner_BG_2.jpg"

import { Box } from "@mui/system"

import Demo_Creator from "./assets/CreatorExperience.mp4"
import playButton from "./assets/PlayIcon.png"

import $40_billion from "./assets/$40_Billion_Hoverstage.svg"
import $40Billions from "./assets/$40_Billion_Normalstage.svg"
import $40BillionsGif from "./assets/$40-Billion.gif"
import $1_Billion from "./assets/1_Billion_Hoverstage.svg"
import $1Billion from "./assets/1_Billion_Normalstage.svg"
import $1BillionGif from "./assets/1-Billion.gif" 
import percent_HoverStage from "./assets/24_Hoverstqge.svg"
import percent_NormalStage from "./assets/24_Normalstqge.svg"
import percent_GifStage from "./assets/27Voice.gif"
import million_hoverstage from "./assets/87-7Million_Hoverstage.svg"
import million_normalstage from "./assets/87-7Million_Normalstage.svg"
import million_gifstage from "./assets/$877-Million.gif"

import Deepgram from "./assets/Deepgram.svg"
import Canva from "./assets/Canva.svg"
import Nuance from "./assets/Nuance.svg"
import Loom from "./assets/loom.svg"
import technology_offering from "./assets/technology offering_venn.gif"
import our_mission from "./assets/Ourmission.png"
import malik from "./assets/Stephen Mallik.png"
import chris from "./assets/Chris Hall.jpg"
import Rothmayr from "./assets/Urs Rothmayr.png"
import mike from "./assets/Mike Nevis.jpg"
import matt from "./assets/Matt Schachte.png"
import Linkedin from "./assets/Linkedin.svg"
import logo from "./assets/Arrivu_Lighter Logo.svg"
import iStoryLogo from "./assets/iStory_Light.svg"

import iStoryHex from "./assets/Center_Hex_Normal.svg"
import iStoryHexHover from "./assets/Center_Hex_Hover.svg"
import redHexa from "./assets/Red_Hexa.svg"
import voiceToText from "./assets/Voice_to_text_1.svg"
import blueHexa from "./assets/Blue_Hexa.svg"
import noCode from "./assets/No_code_1.svg"
import orangeHexa from "./assets/Orange_Hexa.svg"
import textToVoice from "./assets/Text_to_voice_1.svg"
import lightBlueHexa from "./assets/Light_Blue_Hexa.svg"
import textToAction from "./assets/NLU_1.svg"

import productOffering from "./assets/Product_offering.jpg" 



import Messageform from "./forms"
import "./homepage.css"
import Youtube from "./assets/Youtube_Footer.svg";
import twitter from "./assets/Twitter_Footer.svg";
import linkedin_footer from "./assets/Linkedin_footer.svg";

import Facebook_footer from "./assets/Facebook_Footer.svg"

import Form from 'react-bootstrap/Form'
import mail from "./assets/Email.svg" 

import circle1 from "./assets/Circle_1.svg";
import circle2 from "./assets/Circle_2.svg";
import circle3 from "./assets/Circle_3.svg";
import circle4 from "./assets/Circle_4.svg";
// const Responsive = styled("div")(({ theme }) => ({
//     [theme.breakpoints.up("mobile")]: {
//         color: green[500],
//         fontSize:"48px"
//     },
//     [theme.breakpoints.up("tablet")]: {
//         color: "red",
//         fontSize:"24px"
//     },
//     [theme.breakpoints.up("laptop")]: {
//         color: "brown",
//         fontSize:"16px"
//     }
// }))


const pov_ani = {
    initial: { opacity:0, scale:1 },
    animate: {
        opacity: 1,
        scale: 1,
        transition: {
            // type: "spring",
            // bounce: 1,
            // duration: 3
        // type: "ease-in",
        ease: "easeOut",
        delay: 0.5,
        duration: 2,
        },
    },
};

const title_ani = {
    initial: {opacity: 0, y: 100},
    animate: {
        opacity:1,
        y: 0,
        transition: {
            ease: "easeOut",
            duration: 1
        }
    }
};

const desc_ani = {
    initial: {opacity:0, scale:0.5},
    animate: {
        opacity: 1,
        scale: 1,
        transition: {
            delay: 0.5,
            duration: 1.5,
            ease: "easeOut",
        }

    }
};

const gvcm_desc = {
    initial: {opacity:0},
    animate: {
        opacity: 1,
        transition: {
            duration: 1.5,
            ease: "easeOut",
        }

    }
};

const TextVariants = {
    offscreen: {
      opacity: 0,
      transition:{
        duration: 0.2
      }
    },
    onscreen: {
      opacity: 1,
      scale: 1.5
    }
};

function Home() {
    const [subscribe, setSubscribe] = useState("")
    const [selected, setSelected] = useState("Message")
    const [videoplayOne, setvideoPlayOne] = useState(true)

    const [istory_vd, setIStory_vd] = useState(false);
    const [vd_view, setVd_view] = useState(0);

    const [istory_vd1, setIStory_vd1] = useState(false);
    const [istory_vdc1, setIStory_vdc1] = useState(false);

    const [istory_vd2, setIStory_vd2] = useState(false);
    const [istory_vdc2, setIStory_vdc2] = useState(false);
    
    const [istory_vd3, setIStory_vd3] = useState(false);
    const [istory_vdc3, setIStory_vdc3] = useState(false);
    
    const [istory_vd4, setIStory_vd4] = useState(false);
    const [istory_vdc4, setIStory_vdc4] = useState(false);

    const vidRefOne = useRef(null);

    const pov_controls = useAnimation();
    const pov_ref = useRef(null);
    const pov_View = useInView(pov_ref);

    const CM_1controls = useAnimation();
    const CM_1ref = useRef(null);
    const CM_1view = useInView(CM_1ref);
    const CM_1word = "artificial intelligence"

    const GVCM_2controls = useAnimation();
    const GVCM_2ref = useRef(null);
    const GVCM_2view = useInView(GVCM_2ref);
    const GVCM_2word = "Deepgram Raises $25M Series B from Tiger Global and Others to Unlock the Power of Voice Data."

    const GVCM_3controls = useAnimation();
    const GVCM_3ref = useRef(null);
    const GVCM_3view = useInView(GVCM_3ref);
    const GVCM_3word = "Canva raises $200 million to hit $40 billion valuation."

    const GVCM_4controls = useAnimation();
    const GVCM_4ref = useRef(null);
    const GVCM_4view = useInView(GVCM_4ref);
    const GVCM_4word = "Loom Closes $29M Series B After 6x Traffic Spike."



    useEffect(() => {
        const hubspotScript = document.createElement('script');
        hubspotScript.type = "text/javascript"
        hubspotScript.id = "hs-script-loader";
        hubspotScript.src = "//js.hs-scripts.com/6040860.js";
        hubspotScript.async = true;
        hubspotScript.defer = true;
        document.body.appendChild(hubspotScript);
      return () => {
          document.body.removeChild(hubspotScript);
        }
      }, []);

    // useEffect(() => {
    //     if(CM_1view){
    //         CM_1controls.start("onscreen");
    //     }
    //     else {
    //         CM_1controls.start("offscreen");
    //     }

    //     if(GVCM_2view){
    //         GVCM_2controls.start("onscreen");
    //     }
    //     else {
    //         GVCM_2controls.start("offscreen");
    //     }
    //     if(GVCM_3view){
    //         GVCM_3controls.start("onscreen");
    //     }
    //     else {
    //         GVCM_3controls.start("offscreen");
    //     }
    //     if(GVCM_4view){
    //         GVCM_4controls.start("onscreen");
    //     }
    //     else {
    //         GVCM_4controls.start("offscreen");
    //     }
    //     console.log("insinview",pov_View);
    // }, [CM_1view,GVCM_2view,GVCM_3view,GVCM_4view]);

    const handlePlayVideo = (view) => {
        if (videoplayOne) {
            if(view === "playIcon"){
                vidRefOne.current.play();
            }
            setvideoPlayOne(!videoplayOne)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    const vendiagContent = (view) => {
        if(view === "vdc1"){
            // setIStory_vd1(false)
            if(istory_vdc1){
                setIStory_vdc1(false)
            }
            else{
                setIStory_vdc1(true)
            }
        }
        else if(view === "vdc2"){
            if(istory_vdc2){
                setIStory_vdc2(false)
            }
            else{
                setIStory_vdc2(true)
            }
        }
        else if(view === "vdc3"){
            if(istory_vdc3){
                setIStory_vdc3(false)
            }
            else{
                setIStory_vdc3(true)
            }
        }
        else if(view === "vdc4"){
            if(istory_vdc4){
                setIStory_vdc4(false)
            }
            else{
                setIStory_vdc4(true)
            }
        }
    }


    return (
        <>
            <ThemeProvider theme={theme}>

                <Box sx={{ bgcolor: "#151515" }}>
                    <Grid className="sticky">
                        <Header />
                    </Grid>
                    <Box id="arrivu_vision">
                        <Grid container sx={{}} >
                            <Grid item mobile={12}>
                                <Box className="heroBanner">
                                    <Box component="img" sx={{width:"100%"}} src={heroBanner}/>
                                    <Box className="HeroBannerContent" sx={{top: {mobile:"50%",laptop:"40%"}}} >
                                        
                                        <Box sx={{maxWidth:{mobile:"980px",desktop:"1100px",desktopLg:"1400px"}, padding: "0px 22px", marginLeft: "auto", marginRight: "auto",}}>
                                            <motion.div
                                                animate={{ opacity: 1, scale: 1 }}
                                                transition={{
                                                    duration: 2,
                                                    delay: 0.3,
                                                    // ease: [0.5, 0.71, 1, 1.1]
                                                }}
                                                initial={{ opacity: 0, scale: 1 }}
                                            >
                                                <Typography sx={{ fontSize: {mobile: 22,tablet:40,laptop:48},fontWeight:700, color: "white", lineHeight:"unset" }}>Using</Typography>
                                                <Typography sx={{ fontSize: {mobile: 36,tablet:70,laptop:85},fontWeight:700, color: "#45BAF5", lineHeight:"unset" }}>Intelligence</Typography>
                                                <Typography sx={{ fontSize: {mobile: 22,tablet:40,laptop:48},fontWeight:700, color: "white", lineHeight:"unset" }}>to <span style={{color:"#EBCE23"}}>propel creativity.</span></Typography>
                                                <Typography sx={{ fontSize: {mobile: 12,tablet:20,laptop:24},fontWeight:400, color: "white",paddingTop:2, lineHeight:"unset" }}>Applying the power of voice to engage</Typography>
                                                <Typography sx={{ fontSize: {mobile: 12,tablet:20,laptop:24},fontWeight:400, color: "white", lineHeight:"unset" }}>with technology in a natural way.</Typography>
                                            </motion.div>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box id="voice_power" className="voice_power" sx={{ paddingTop: 10, paddingBottom: 10, height: {mobile:1300, tablet:750, laptop:525} }}>
                        <motion.div initial='initial' whileInView="animate" variants={title_ani}>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Typography sx={{ fontSize: { mobile: 16, tablet: 20, laptop: 24 }, color: "#E6E8EB", letterSpacing: { mobile: 2, tablet: 5, laptop: 10 } }}>THE</Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", }}>
                                <Typography className="vp_gradient" sx={{ fontSize: { mobile: 28, tablet: 40, laptop: 48 },letterSpacing:-1, fontWeight: "bold", borderBottom: "2px solid #4B4B4B" }}>POWER OF VOICE</Typography>
                            </Box>
                        
                            <motion.div initial='initial' whileInView="animate" variants={desc_ani}>
                                <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "40px" }}>
                                    <Typography sx={{ fontSize: { mobile: 16, tablet: 20, laptop: 22 }, textAlign: "center", color: "#EFEFEF", width: { mobile: 300, tablet: 550, laptop: 641 }, marginBottom:5 }}>Voice is becoming the most disruptive force in the digital world.</Typography>
                                </Box>
                            </motion.div>
                        </motion.div>
                        <Box>
                            <motion.div initial='initial' whileInView="animate" variants={pov_ani}>
                            
                                <Box style={{padding:"0px 22px"}}>
                                    <Grid container sx={{ maxWidth:{mobile:"980px",desktop:"1100px",desktopLg:"1400px"},display:"flex", flexDirection:"row",alignItems:"center", marginLeft: "auto", marginRight: "auto",justifyContent:"space-between" }}>
                                        <Grid item mobile={12} tablet={6} laptop={3}>
                                            <Box sx={{ placeContent: "center",textAlign:"-webkit-center"}}>
                                                <motion.div
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 1.3 }}
                                                    transition={{ duration: 1.5 }}
                                                >
                                                    <Box className="content vp_1" sx={{ bgcolor: "#151515",minWidth: "-webkit-fill-available", width: "203px", height: "250px", borderRadius: 1, display: "flex", alignItems: "center", justifyContent: "center", margin: 2 }}>
                                                        <img className="normal"  src={million_gifstage} />
                                                        <Box className="content_hover">
                                                            <img src={million_hoverstage} sx={{ width:{mobile: 100} }} />
                                                            <Typography sx={{ fontSize: 32, fontWeight: "bold", color: "white" }}>$87.7 Million</Typography>
                                                            <Typography sx={{ fontSize: 16, color: "white", padding: 0.5 }}>Voice enabled smart speaker bases installed in the US</Typography>
                                                        </Box>
                                                    </Box>
                                                </motion.div>
                                            </Box>
                                        </Grid>
                                        <Grid item mobile={12} tablet={6} laptop={3}>
                                            <Box sx={{ placeContent: "center",textAlign:"-webkit-center" }}>
                                                <motion.div
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 1.3 }}
                                                    transition={{ duration: 1.5 }}
                                                >
                                                    <Box className="content vp_2" sx={{ bgcolor: "#151515",minWidth: "-webkit-fill-available", width: "203px", height: "250px", borderRadius: 1, display: "flex", alignItems: "center", justifyContent: "center", margin: 2 }}>
                                                        <img className="normal"  src={$40BillionsGif} />
                                                        <Box className="content_hover">
                                                            <img src={$40_billion} />
                                                            <Typography sx={{ fontSize: 32, fontWeight: "bold", color: "white" }}>$40 Billion</Typography>
                                                            <Typography sx={{ fontSize: 16, color: "white", padding: 0.5 }}>Voice activated Ecommerce in US by the end of 2021</Typography>
                                                        </Box>
                                                    </Box>
                                                </motion.div>
                                            </Box>
                                        </Grid>
                                        <Grid item mobile={12} tablet={6} laptop={3}>
                                            <Box sx={{ placeContent: "center",textAlign:"-webkit-center" }}>
                                                <motion.div
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 1.3 }}
                                                    transition={{ duration: 1.5 }}
                                                >
                                                    <Box className="content vp_3" sx={{ bgcolor: "#151515",minWidth: "-webkit-fill-available", width: "203px", height: "250px", borderRadius: 1, display: "flex", alignItems: "center", justifyContent: "center", margin: 2 }}>
                                                        <img className="normal"  src={percent_GifStage} />
                                                        <Box className="content_hover">
                                                            <img src={percent_HoverStage} />
                                                            <Typography sx={{ fontSize: 32, fontWeight: "bold", color: "white" }}>27%</Typography>
                                                            <Typography sx={{ fontSize: 16, color: "white", padding: 0.5 }}>Adults who would rather use Voice assistance than visit a website</Typography>
                                                        </Box>
                                                    </Box>
                                                </motion.div>
                                            </Box>
                                        </Grid>
                                        <Grid item mobile={12} tablet={6} laptop={3}>
                                            <Box sx={{ placeContent: "center",textAlign:"-webkit-center" }}>
                                                <motion.div
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 1.3 }}
                                                    transition={{ duration: 1.5 }}
                                                >
                                                    <Box className="content vp_4" sx={{ bgcolor: "#151515",minWidth: "-webkit-fill-available", width: "203px", height: "250px", borderRadius: 1, display: "flex", alignItems: "center", justifyContent: "center", margin: 2 }}>
                                                        <img className="normal" src={$1BillionGif} />
                                                        <Box className="content_hover">
                                                            <img src={$1_Billion} />
                                                            <Typography sx={{ fontSize: 32, fontWeight: "bold", color: "white" }}>1 Billion</Typography>
                                                            <Typography sx={{ fontSize: 16, color: "white", padding: 0.5 }}>Voice searches conducted each month</Typography>
                                                        </Box>
                                                    </Box>
                                                </motion.div>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </motion.div>
                        {/* </motion.div> */}
                        </Box>
                    </Box>
                    <Box sx={{ paddingTop: 10, bgcolor: "#151515", paddingBottom: 10 }}>
                        
                        <Grid>
                            <motion.div initial='initial' whileInView="animate" variants={title_ani}>
                                <Box id="uniqueTechnology" sx={{ display: "flex", justifyContent: "center" }}>
                                    <Typography sx={{ fontSize: { mobile: 16, tablet: 20, laptop: 24 }, color: "#E6E8EB", letterSpacing: { mobile: 2, tablet: 5, laptop: 10 } }}>OUR UNIQUE</Typography>
                                </Box>
                                <Box sx={{ display: "flex",textAlign:"center", justifyContent: "center", }}>
                                    <Typography className="gradient" sx={{ fontSize: { mobile: 28, tablet: 40, laptop: 48 },letterSpacing:-1, fontWeight: "bold", borderBottom: "2px solid #4B4B4B" }}>TECHNOLOGY OFFERING</Typography>
                                </Box>
                                <motion.div initial='initial' whileInView="animate" variants={desc_ani}>
                                    <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "24px" }}>
                                        <Typography sx={{ fontSize: { mobile: 16, tablet: 20, laptop: 22 }, textAlign: "center", color: "#EFEFEF", width: { mobile: 300, tablet: 550, laptop: 641 } }}>Billions of Dollars Have Been Invested In Technologies That Dont Hit The Arrivu Sweet Spot </Typography>
                                    </Box>
                                </motion.div>
                            </motion.div>
                            {/* <Box sx={{ display: "flex", justifyContent: "center", padding:"40px 40px" }}>
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "-webkit-fill-available", height: { mobile: "280px", tablet: "400px", laptop: "656px" }, bgcolor: "rgb(33 37 41 / 20%)", borderRadius: "24px" }}>
                                    <Box component="img" sx={{ padding: 3, width: { mobile: "300px", tablet: "550px", laptop: "900px" }, }} src={technology_offering} />
                                </Box> 
                            </Box> */}
                            <Box sx={{ paddingTop:{mobile: 3, tablet: 3, laptop:3}}} onMouseOver={() => { setIStory_vd(true);setVd_view(1); }} onMouseOut={() => { setIStory_vd(false);setVd_view(0) }} >
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                    // onMouseOver={() => { setIStory_vd(true) }} onMouseOut={() => { setIStory_vd(false) }} 
                                    >
                                    <motion.div
                                        key="venndiagram1"
                                        animate={{ opacity: vd_view }}
                                        transition={{ duration: 1 }}
                                    >
                                        <Box 
                                            sx={{display:"flex",alignItems:"center"}}
                                        >
                                            
                                            <Box sx={{fontSize: {mobile: 6,tablet:12,laptop:12,desktopLg:16}}} style={(istory_vdc3)? null : {visibility:"hidden"}}>
                                                <Typography sx={{fontSize: {mobile: 6,tablet:12,laptop:14,desktopLg:18},fontWeight:700, color: "#F8723E", lineHeight:"unset",marginTop: {mobile:"3px",tablet:"6px",laptop:"10px"} }}>Text-To-Voice</Typography>
                                                <ul style={{color:"white",display:"inline-block",textAlign:"initial",fontWeight:400}} className="Ulist ulResp">
                                                    <li>Alforthemic</li>
                                                    <li>WellSaid</li>
                                                </ul>
                                            </Box>
                                            <Box className="vennDiagram " onClick={() => vendiagContent('vdc3')} onMouseOver={() => { setIStory_vd3(true) }} onMouseOut={() => { setIStory_vd3(false) }} >
                                                <Box component="img" sx={{ width: { mobile: "90px", tablet: "180px", laptop: "250px", desktopLg: "330px" } }} src={orangeHexa} />
                                                <Box className="vennDiagramContent" sx={{top:"50%",width:{ mobile:"65px", tablet:"130px", laptop:"170px", desktopLg:"220px" }}}>
                                                    <Box sx={{textAlign:"center"}}>
                                                        <Box component="img" sx={istory_vd3 ?
                                                            {width: {mobile:"20px",tablet:"45px",laptop:"70px",desktop:"80px"}} 
                                                            : {width: {mobile:"40px",tablet:"90px",laptop:"100px",desktop:"130px"}}} src={textToVoice}></Box>
                                                        {istory_vd3 ?
                                                            <Typography sx={{ fontSize: {mobile: 6,tablet:14,laptop:18,desktopLg:21},fontWeight:700, color: "white", lineHeight:"unset",marginTop: {mobile:"3px",tablet:"6px",laptop:"10px"} }}>Text-To-Voice</Typography>
                                                            : null
                                                        }
                                                    </Box>
                                                </Box>       
                                            </Box>
                                        </Box>
                                    </motion.div>

                                    <motion.div
                                        key="venndiagram2"
                                        animate={{ opacity: vd_view }}
                                        transition={{ duration: 1 }}
                                    >
                                        <Box 
                                            sx={{display:"flex",alignItems:"center"}}
                                        >
                                            <Box className="vennDiagram" onClick={() => vendiagContent('vdc4')} 
                                                onMouseOver={() => { setIStory_vd4(true) }} onMouseOut={() => { setIStory_vd4(false) }} >
                                                <Box component="img" sx={{ width: { mobile: "90px", tablet: "180px", laptop: "250px", desktopLg: "330px" } }} src={lightBlueHexa} />
                                                <Box className="vennDiagramContent" sx={{ top:"50%", width:{ mobile:"65px", tablet:"130px", laptop:"170px", desktopLg:"220px" }}}>
                                                    <Box sx={{textAlign:"center"}}>
                                                        <Box component="img" sx={istory_vd4 ?
                                                            {width: {mobile:"20px",tablet:"45px",laptop:"70px",desktop:"80px"}} 
                                                            : {width: {mobile:"40px",tablet:"90px",laptop:"100px",desktop:"130px"}}} src={textToAction}></Box>
                                                        {istory_vd4 ?
                                                            <>
                                                                <Typography sx={{ fontSize: {mobile: 6,tablet:14,laptop:18,desktopLg:21},fontWeight:700, color: "white", lineHeight:"unset",marginTop: {mobile:"3px",tablet:"6px",laptop:"10px"} }}>Text-To-Action (NLU)</Typography>
                                                                <Typography sx={{ fontSize: {mobile: 5,tablet:8,laptop:12,desktopLg:14},fontWeight:700, color: "white", lineHeight:"unset",marginTop: "4px" }}>Natural Language Understanding</Typography>
                                                            </>
                                                            : null
                                                        }
                                                    </Box>
                                                </Box>
                                            </Box>
                                            
                                            <Box sx={{fontSize: {mobile: 6,tablet:11,laptop:12,desktopLg:16}}} style={(istory_vdc4 )? null : {visibility:"hidden"}}>
                                                <Typography sx={{ fontSize: {mobile: 6,tablet:12,laptop:14,desktopLg:18},fontWeight:700, color: "#58C1F6", lineHeight:"unset",marginTop: {mobile:"3px",tablet:"6px",laptop:"10px"} }}>Text-To-Action</Typography> 
                                                <Typography sx={{ fontSize: {mobile: 6,tablet:12,laptop:14,desktopLg:18},fontWeight:700, color: "#58C1F6", lineHeight:"unset" }}>(NLU)</Typography> 
                                                <Typography sx={{ width:{mobile:"52px",tablet:"100px",laptop:"100px",desktopLg:"150px"}, fontSize: {mobile: 6,tablet:8,laptop:10,desktopLg:12},fontWeight:700, color: "#B3B3B3", lineHeight:"unset",marginTop:"3px"}}>Natural Language Understanding</Typography>
                                                {/* <Typography sx={{ fontSize: {mobile: 6,tablet:6,laptop:8,desktopLg:10},fontWeight:700, color: "#B3B3B3", lineHeight:"unset", }}>Natural Language Understanding</Typography> */}
                                                <ul style={{color:"white",display:"inline-block",textAlign:"initial",fontWeight:400}} className="Ulist ulResp">
                                                    <li>Amazon</li>
                                                    <li>Lex</li>
                                                    <li>Dialogflow</li>
                                                    <li>Rasa</li>
                                                    <li>Luis</li>
                                                </ul>
                                            </Box>
                                        </Box>
                                    </motion.div>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <motion.div
                                        key="venndiagram1"
                                        animate={{ opacity: vd_view }}
                                        transition={{ duration: 1 }}
                                    >
                                        <Box 
                                            sx={{display:"flex",alignItems:"center"}}
                                            >
                                            <Box sx={{fontSize: {mobile: 6,tablet:12,laptop:12,desktopLg:16}}} style={(istory_vdc1) ? null : {visibility:"hidden"}}>
                                                <Typography sx={{fontSize: {mobile: 6,tablet:12,laptop:14,desktopLg:18},fontWeight:700, color: "#B8235F", lineHeight:"unset",marginTop: {mobile:"3px",tablet:"6px",laptop:"10px"} }}>Voice-To-Text</Typography>
                                                <ul style={{color:"white",display:"inline-block",textAlign:"initial",fontWeight:400}} className="Ulist ulResp">
                                                    <li>Deepgram</li>
                                                    <li>Rev.AI</li>
                                                </ul>
                                            </Box>
                                            <Box className="vennDiagram " onClick={() => vendiagContent('vdc1')} onMouseOver={() => { setIStory_vd1(true) }} onMouseOut={() => { setIStory_vd1(false) }} >
                                                <Box component="img" sx={{ width: { mobile: "90px", tablet: "180px", laptop: "250px", desktopLg: "330px" } }} src={redHexa} />
                                                <Box className="vennDiagramContent" sx={{top:"50%",width:{ mobile:"65px", tablet:"130px", laptop:"170px", desktopLg:"220px" }}}>
                                                    <Box sx={{textAlign:"center"}}>
                                                        <Box component="img" sx={istory_vd1 ?
                                                            {width: {mobile:"20px",tablet:"45px",laptop:"70px",desktop:"80px"}} 
                                                            : {width: {mobile:"40px",tablet:"90px",laptop:"100px",desktop:"130px"}}} src={voiceToText}></Box>
                                                        {istory_vd1 ?
                                                            <Typography sx={{ fontSize: {mobile: 6,tablet:14,laptop:18,desktopLg:21},fontWeight:700, color: "white", lineHeight:"unset",marginTop: {mobile:"3px",tablet:"6px",laptop:"10px"} }}>Voice-To-Text</Typography>
                                                            : null
                                                        }
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </motion.div>

                                    <Box className="vennDiagram" sx={{margin:{mobile:"0px 0px 0px 0px",tablet:"0px 0px 0px 0px",laptop:"0px"}}} >
                                        <Box component="img" sx={{ width: { mobile: "90px", tablet: "180px", laptop: "250px", desktopLg: "330px" } }} src={ istory_vd ? iStoryHexHover : iStoryHex} />
                                        <Box className="vennDiagramContent" sx={{top:"50%",width:{ mobile:"65px", tablet:"130px", laptop:"170px", desktopLg:"220px" }}}>
                                            <Box sx={{textAlign:"center"}}>
                                                <Box component="img" sx={{ width: {mobile:"40px",tablet:"90px",laptop:"90px",desktop:"140px"} }} src={logo}></Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <motion.div
                                        key="venndiagram1"
                                        animate={{ opacity: vd_view }}
                                        transition={{ duration: 1 }}
                                    >
                                        <Box 
                                            sx={{display:"flex",alignItems:"center"}}
                                        >
                                            <Box className="vennDiagram" onClick={() => vendiagContent('vdc2')} 
                                                onMouseOver={() => { setIStory_vd2(true) }} onMouseOut={() => { setIStory_vd2(false) }} >
                                                <Box component="img" sx={{ width: { mobile: "90px", tablet: "180px", laptop: "250px", desktopLg: "330px" } }} src={blueHexa} />
                                                <Box className="vennDiagramContent" sx={{ top:"50%", width:{ mobile:"65px", tablet:"130px", laptop:"170px", desktopLg:"220px" }}}>
                                                    <Box sx={{textAlign:"center"}}>
                                                        <Box component="img" sx={istory_vd2 ?
                                                            {width: {mobile:"20px",tablet:"45px",laptop:"70px",desktop:"80px"}} 
                                                            : {width: {mobile:"40px",tablet:"90px",laptop:"100px",desktop:"130px"}}} src={noCode}></Box>
                                                        {istory_vd2 ?
                                                            <Typography sx={{ fontSize: {mobile: 6,tablet:14,laptop:18,desktopLg:21},fontWeight:700, color: "white", lineHeight:"unset",marginTop: {mobile:"3px",tablet:"6px",laptop:"10px"} }}>No-Code Platform</Typography>
                                                            : null
                                                        }
                                                    </Box>
                                                </Box>
                                            </Box>
                                        
                                            <Box sx={{fontSize: {mobile: 6,tablet:11,laptop:12,desktopLg:16}}} style={(istory_vdc2 )? null : {visibility:"hidden"}}>
                                                <Typography sx={{ fontSize: {mobile: 6,tablet:12,laptop:14,desktopLg:18},fontWeight:700, color: "#1A68BF", lineHeight:"unset",marginTop: {mobile:"3px",tablet:"6px",laptop:"10px"} }}>No-Code Platform</Typography>
                                                <ul style={{color:"white",display:"inline-block",textAlign:"initial",fontWeight:400}} className="Ulist ulResp">
                                                    <li>Quickbase</li>
                                                    <li>Appian</li>
                                                    <li>PowerApps</li>
                                                    <li>APEX</li>
                                                    <li>Kissflow</li>
                                                    <li>AppMaker</li>
                                                </ul>
                                            </Box>
                                        </Box>
                                    </motion.div>  
                                </Box>
                            </Box>
                            {/* </motion.div> */}
                        </Grid>
                        {/* Growing AI market section */}
                        <motion.div initial='initial' whileInView="animate" variants={title_ani}>
                            <Box sx={{ display: "flex", justifyContent: "center", paddingTop: 10 }}>
                                <Typography sx={{ fontSize: { mobile: 16, tablet: 20, laptop: 24 }, color: "#E6E8EB", letterSpacing: { mobile: 2, tablet: 5, laptop: 10 } }}>THE</Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", }}>
                                <Typography className="gv_gradient" sx={{ width: {mobile:290, tablet: "auto"},fontSize: { mobile: 28, tablet: 40, laptop: 48 },letterSpacing:-1, fontWeight: "bold", textAlign:"center",borderBottom: "2px solid #4B4B4B" }}>GROWING VOICE AND CREATOR MARKET</Typography>
                            </Box>
                        <Box style={{padding:"0px 22px"}}>
                        <Grid container sx={{ maxWidth:{mobile:"980px",desktop:"1100px",desktopLg:"1400px"}, marginLeft: "auto", marginRight: "auto",paddingTop:3 }}>
                            <Grid item sx={{ padding: {mobile: "10px 10px" } }} mobile={12} tablet={6} laptop={6}>
                                <Box sx={{ bgcolor: "#212529",borderRadius: {laptop: 4} }}>
                                    <Box sx={{display: {mobile:"block",tablet:"block",laptop:"block",desktop:"flex"},padding:"20px 20px"}} >
                                        <Box style={{textAlign:"center"}}>
                                            <Box component="img" src={Nuance} className="gv_img" style={{borderRadius: "8px",backgroundColor: "#EFEFEF",textAlign: "center"}} />
                                        </Box>
                                        <Box sx={{paddingLeft:{mobile:"0px",desktop:"24px"},paddingTop:{mobile:"24px",desktop:"0px"},paddingBottom:{mobile:"24px",desktop:"0px"},alignSelf:"center"}}>
                                            <motion.div
                                                initial="initial"
                                                whileInView="animate" 
                                                variants={gvcm_desc}
                                            >
                                                <Typography sx={{ marginBottom: {mobile:"32px"}, fontSize:{mobile:"16px", laptop:"16px"}, color: "white", textAlign:"initial"}}>
                                                    {/* {GVCM_1word.split("").map((r, id) => (
                                                        <motion.span
                                                            animate={GVCM_1controls}
                                                            variants={TextVariants}
                                                            transition={{
                                                            duration: 0.2,
                                                            delay: id * 0.01
                                                            }}
                                                            key={id}
                                                        >
                                                            {r}
                                                        </motion.span>
                                                    ))} */}
                                                    Microsoft purchasing Nuance significantly expands its capabilities with AI and voice recognition.
                                                </Typography>
                                                <a className="MsA g_AI" style={{textDecoration:"none",padding: "10px 15px",backgroundColor: "#006339", margin: 1, color:"#FFF", fontWeight:"500"}} href="https://www.windowscentral.com/microsoft-could-purchase-nuance" target="_blank" variant="contained" >Read more <ArrowForwardIosIcon sx={{fontSize:"16px",verticalAlign:"middle"}} /></a>   
                                            </motion.div>
                                            {/* <Button style={{textTransform:"none"}} href="https://www.windowscentral.com/microsoft-could-purchase-nuance" target="_blank" variant="contained" sx={{ bgcolor: "#006339" }} endIcon={<ArrowForwardIosIcon />}>Read more</Button>    */}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sx={{ padding: {mobile: "10px 10px" } }} mobile={12} tablet={6} laptop={6}>
                                <Box sx={{ bgcolor: "#212529",borderRadius: {laptop: 4} }}>
                                    <Box sx={{display: {mobile:"block",tablet:"block",laptop:"block",desktop:"flex"},padding:"20px 20px"}} >
                                        <Box style={{textAlign:"center"}}>
                                            <Box component="img" className="gv_img" src={Deepgram} style={{borderRadius: "8px",backgroundColor: "#EFEFEF",textAlign: "center"}} />
                                        </Box>
                                        <Box sx={{paddingLeft:{mobile:"0px",desktop:"24px"},paddingTop:{mobile:"24px",desktop:"0px"},paddingBottom:{mobile:"24px",desktop:"0px"},alignSelf:"center"}}>
                                            <motion.div
                                                initial="initial"
                                                whileInView="animate" 
                                                variants={gvcm_desc}
                                            >
                                                <Typography sx={{ marginBottom: {mobile:"32px"},fontSize: {mobile:"16px", laptop:"16px"}, color: "white", textAlign:"initial"}}>
                                                    Deepgram Raises $25M Series B from Tiger Global and Others to Unlock the Power of Voice Data.
                                                </Typography>
                                                <a className="DeepgramA g_AI" style={{textDecoration:"none",padding: "10px 15px",backgroundColor: "#DE5824", margin: 1, color:"#FFF", fontWeight:"500"}} href="https://www.enterpriseai.news/2021/02/04/deepgram-raises-25m-series-b-from-tiger-global-and-others-to-unlock-the-power-of-voice-data/" target="_blank" variant="contained" >Read more <ArrowForwardIosIcon sx={{fontSize:"16px",verticalAlign:"middle"}} /></a>   
                                            </motion.div>
                                            {/* <Button style={{textTransform:"none"}} href="https://www.enterpriseai.news/2021/02/04/deepgram-raises-25m-series-b-from-tiger-global-and-others-to-unlock-the-power-of-voice-data/" target="_blank" variant="contained" sx={{ bgcolor: "#DE5824" }} endIcon={<ArrowForwardIosIcon />}>Read more</Button>    */}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sx={{ padding: {mobile: "10px 10px" } }} mobile={12} tablet={6} laptop={6}>
                                <Box sx={{ bgcolor: "#212529",borderRadius: {laptop: 4} }}>
                                    <Box sx={{display: {mobile:"block",tablet:"block",laptop:"block",desktop:"flex"},padding:"20px 20px"}} >
                                        <Box style={{textAlign:"center"}}>
                                            <Box component="img" className="gv_img" src={Canva} style={{borderRadius: "8px",backgroundColor: "#EFEFEF",textAlign: "center"}} />
                                        </Box>
                                        <Box sx={{paddingLeft:{mobile:"0px",desktop:"24px"},paddingTop:{mobile:"24px",desktop:"0px"},paddingBottom:{mobile:"24px",desktop:"0px"},alignSelf:"center"}}>
                                            <motion.div
                                                initial="initial"
                                                whileInView="animate" 
                                                variants={gvcm_desc}
                                            >
                                                <Typography sx={{ marginBottom: {mobile:"32px"},fontSize: {mobile:"16px", laptop:"16px"}, color: "white", textAlign:"initial"}}>
                                                    Canva raises $200 million to hit $40 billion valuation.
                                                </Typography>
                                                <Box>
                                                    <a className="CanvaA g_AI" style={{textDecoration:"none",padding: "10px 15px",backgroundColor: "#3DA4D8", margin: 1, color:"#FFF", fontWeight:"500"}} href="https://techstory.in/canva-raises-200-million-to-hit-40-billion-valuation/#:~:text=Editing%20platform%20Canva%20has%20become%20one%20of%20the,investors%2C%20and%20was%20led%20by%20T.%20Rowe%20Price." target="_blank" variant="contained" >Read more <ArrowForwardIosIcon sx={{fontSize:"16px",verticalAlign:"middle"}} /></a>   
                                                </Box>
                                            </motion.div>
                                            {/* <Button style={{textTransform:"none"}} href="https://techstory.in/canva-raises-200-million-to-hit-40-billion-valuation/#:~:text=Editing%20platform%20Canva%20has%20become%20one%20of%20the,investors%2C%20and%20was%20led%20by%20T.%20Rowe%20Price." target="_blank" variant="contained" sx={{ bgcolor: "#3DA4D8" }} endIcon={<ArrowForwardIosIcon />}>Read more</Button>    */}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sx={{ padding: {mobile: "10px 10px" } }} mobile={12} tablet={6} laptop={6}>
                                <Box sx={{ bgcolor: "#212529",borderRadius: {laptop: 4} }}>
                                    <Box sx={{display: {mobile:"block",tablet:"block",laptop:"block",desktop:"flex"},padding:"20px 20px"}} >
                                        <Box style={{textAlign:"center"}}>
                                            <Box component="img" className="gv_img" src={Loom} sx={{}} style={{borderRadius: "8px",backgroundColor: "#EFEFEF",textAlign: "center"}} />
                                        </Box>
                                        <Box sx={{paddingLeft:{mobile:"0px",desktop:"24px"},paddingTop:{mobile:"24px",desktop:"0px"},paddingBottom:{mobile:"24px",desktop:"0px"},alignSelf:"center"}}>
                                            <motion.div
                                                initial="initial"
                                                whileInView="animate" 
                                                variants={gvcm_desc}
                                            >
                                                <Typography sx={{ marginBottom: {mobile:"32px"},fontSize: {mobile:"16px", laptop:"16px"}, color: "white",textAlign:"initial"}}>
                                                    Loom Closes $29M Series B After 6x Traffic Spike.
                                                </Typography>
                                                <Box>
                                                    <a className="LoomA g_AI" style={{textDecoration:"none",padding: "10px 15px",backgroundColor: "#9E0945", margin: 1, color:"#FFF", fontWeight:"500"}} href="https://blog.getlatka.com/loom-funding-customers/" target="_blank" variant="contained" >Read more <ArrowForwardIosIcon sx={{fontSize:"16px",verticalAlign:"middle"}} /></a>   
                                                </Box>
                                            </motion.div>
                                            {/* <Button style={{textTransform:"none"}} href="https://blog.getlatka.com/loom-funding-customers/" target="_blank" variant="contained" sx={{ bgcolor: "#9E0945" }} endIcon={<ArrowForwardIosIcon />}>Read more</Button>    */}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        </Box>
                        </motion.div>
                    </Box>
                    <Box id="productOffering" className="back" sx={{ paddingTop: 10, bgcolor: "#0056b3", paddingBottom: 10 }}>
                        <motion.div initial='initial' whileInView="animate" variants={title_ani}>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Typography sx={{ fontSize: { mobile: 16, tablet: 20, laptop: 24 }, color: "#E6E8EB", letterSpacing: { mobile: 2, tablet: 5, laptop: 10 } }}>OUR UNIQUE</Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", }}>
                                <Typography sx={{ fontSize: { mobile: 28, tablet: 40, laptop: 48 },letterSpacing:-1, fontWeight: "bold", borderBottom: "2px solid white", color: "white" }}>PRODUCT OFFERING</Typography>
                            </Box>
                        <Box sx={{ margin:{mobile:"0px 20px"},padding:{mobile:"40px 0px 0px 0px"},display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {/* <Box className="border border-slice" > */}
                                {/* <Box sx={{ bgcolor: "#222" }}>   */}
                                    <Box component="img" className="border" sx={{ width: {mobile:"100%",tablet:"100%",laptop:"850px",desktop:"1000px"}}} src={productOffering}></Box>

                                    {/* <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Box className={"video_slide_shown"} sx={{ width: "-webkit-fill-available" }}>
                                            <video onClick={() => handlePlayVideo('video')} style={{width:"inherit"}} ref={vidRefOne} controls={!videoplayOne}>
                                                <source src={Demo_Creator} type="video/mp4" />
                                            </video>
                                        </Box>
                                        {
                                            videoplayOne ? 
                                                <Box className="content_video" component={"img"} sx={{ position: "absolute", width: "65px", height: "65px" }} onClick={() => handlePlayVideo('playIcon')} src={playButton} />
                                            : null
                                        }
                                    </Box> */}
                                {/* </Box> */}
                            {/* </Box> */}
                        </Box>
                        <Box sx={{display: "flex",justifyContent: "center",marginTop:"20px"}}>
                            <a className="learnMore" style={{textDecoration:"none",padding: "15px 70px",backgroundColor: "white", margin: 1, color:"#0057B8", fontWeight:"700"}} href="https://istorylive.com/" target="_blank" variant="contained" >Learn more</a>   
                        </Box>
                        </motion.div>
                    </Box>
                    <Box id="companyMission" className="gradientMission" sx={{ bgcolor: "#161616", paddingTop: 10, paddingBottom: 10 }}>
                        <motion.div initial='initial' whileInView="animate" variants={title_ani}>                   
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Typography sx={{ fontSize: { mobile: 16, tablet: 20, laptop: 24 }, color: "#E6E8EB", letterSpacing: { mobile: 2, tablet: 5, laptop: 10 } }}>OUR COMPANY</Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", }}>
                                <Typography className="vp_gradient" sx={{ fontSize: { mobile: 28, tablet: 40, laptop: 48 },letterSpacing:-1, fontWeight: "bold", borderBottom: "2px solid #4B4B4B" }}>MISSION</Typography>
                            </Box>

                            <Box sx={{paddingTop:5}}>
                                <Box sx={{ width:"100%",maxWidth:{mobile:"980px",desktop:"1100px",desktopLg:"1400px"}, display:"flex", flexDirection:"row",alignItems:"center", marginLeft: "auto", marginRight: "auto",justifyContent:"space-between" }} >
                                    <Grid container sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                        <Box>
                                            <Box sx={{display:"flex",marginBottom:2}}>
                                                <Typography sx={{width:{mobile:"0px",tablet:"280px",laptop:"400px",desktop:"500px",desktopLg:"665px"}}}>
                                                    
                                                </Typography>
                                                <Box>
                                                    <Box component="img" sx={{ height:{mobile:"100px",tablet:"150px"},paddingLeft:2 }} src={circle1} />
                                                </Box>
                                                <Typography sx={{ alignSelf:"center",width:{mobile:"100%",tablet:"280px",laptop:"400px",desktop:"500px",desktopLg:"665px"},fontSize: {mobile:"18px",tablet:"24px"}, color: "#EFEFEF",paddingLeft:2,paddingRight:{mobile:2,tablet:0}}}>
                                                    To offer the world the ability to leverage the power of <span className="cm1_text">artificial intelligence</span>
                                                    {/* <motion.span initial="initial" whileInView="animate" variants={cm1_text} > */}
                                                    {/* <span className="cm1_text"> */}
                                                        {/* {CM_1word.split("").map((r, id) => (
                                                            <motion.span
                                                                animate={CM_1controls}
                                                                variants={TextVariants}
                                                                transition={{
                                                                duration: 1,
                                                                delay: id * 0.02
                                                                }}
                                                                key={id}
                                                            >
                                                                {r}
                                                            </motion.span>
                                                        ))} */}
                                                        {/* artificial intelligence */}
                                                    {/* </span> */}
                                                    {/* </motion.span> */}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display:"flex",marginBottom:2}}>
                                                <Typography sx={{ alignSelf:"center",width:{mobile:"100%",tablet:"280px",laptop:"400px",desktop:"500px",desktopLg:"665px"},fontSize: {mobile:"18px",tablet:"24px"}, color: "#EFEFEF",paddingRight:{mobile:0,tablet:2},paddingLeft:{mobile:2,tablet:0}}}>
                                                    To enable human potential with the power of simplification and <span className="cm2_text">Voice/AI</span>
                                                </Typography>
                                                <Box component="img" sx={{ height:{mobile:"100px",tablet:"150px"},paddingRight:{mobile:2,tablet:0} }} src={circle2} />
                                                <Typography sx={{width:{mobile:"0px",tablet:"280px",laptop:"400px",desktop:"500px",desktopLg:"665px"}}}>
                                                    
                                                </Typography>
                                            </Box>
                                            <Box sx={{display:"flex",marginBottom:2}}>
                                                <Typography sx={{width:{mobile:"0px",tablet:"280px",laptop:"400px",desktop:"500px",desktopLg:"665px"}}}>
                                                    
                                                </Typography>
                                                <Box component="img" sx={{ height:{mobile:"100px",tablet:"150px"},paddingLeft:2 }} src={circle3} />
                                                <Typography sx={{ alignSelf:"center",width:{mobile:"100%",tablet:"280px",laptop:"400px",desktop:"500px",desktopLg:"665px"},fontSize: {mobile:"18px",tablet:"24px"}, color: "#EFEFEF",paddingLeft:2,paddingRight:{mobile:2,tablet:0}}}>
                                                    To allow creative people to use technology in a <span className="cm3_text">natural way</span>
                                                </Typography>
                                            </Box>
                                            <Box sx={{display:"flex",marginBottom:2}}>
                                                <Typography sx={{ alignSelf:"center",width:{mobile:"100%",tablet:"280px",laptop:"400px",desktop:"500px",desktopLg:"665px"},fontSize: {mobile:"18px",tablet:"24px"}, color: "#EFEFEF",paddingRight:{mobile:0,tablet:2},paddingLeft:{mobile:2,tablet:0}}}>
                                                    To empower people with the tools needed to express their <span className="cm4_text">passions and objectives</span>
                                                </Typography>
                                                <Box component="img" sx={{ height:{mobile:"100px",tablet:"150px"},paddingRight:{mobile:2,tablet:0} }} src={circle4} />
                                                <Typography sx={{width:{mobile:"0px",tablet:"280px",laptop:"400px",desktop:"500px",desktopLg:"665px"}}}>
                                                    
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Box>
                            </Box>
                        </motion.div>

                        
                    </Box>
                    
                    <Box id="teamMembers" sx={{ paddingTop: 10, bgcolor: "#1D1D1D"}}>
                        <Box sx={{ maxWidth:{mobile:"980px",desktop:"1100px",desktopLg:"1400px"}, marginLeft:"auto", marginRight: "auto" }}>
                            <motion.div initial='initial' whileInView="animate" variants={title_ani}>  
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Typography sx={{ fontSize: { mobile: 16, tablet: 20, laptop: 24 }, color: "#E6E8EB", letterSpacing: { mobile: 2, tablet: 5, laptop: 10 } }}>MEET OUR</Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "center", }}>
                                    <Typography className="gradient" sx={{ fontSize: { mobile: 28, tablet: 40, laptop: 48 },letterSpacing:-1, fontWeight: "bold", borderBottom: "2px solid #4B4B4B" }}>ARRIVU TEAM</Typography>
                                </Box>
                            </motion.div>
                            <Box sx={{ padding: {mobile:"0px 22px 40px 22px",tablet:"40px 22px 80px 22px"} }} >
                                <Grid container sx={{ display: "flex", justifyContent: "center" }} >
                                    
                                    <Grid item sx={{padding:"40px"}}>
                                        <Card sx={{ width: { mobile: 300, tablet: 300, laptop: 345 },bgcolor: "#212529", height: 540, borderRadius: 4 }}>
                                            <CardMedia
                                                component="img"
                                                height="250"
                                                image={chris}
                                                alt="name of product"
                                            />
                                            <CardContent sx={{ bgcolor: "#212529", height: 250 }}>
                                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Box>
                                                        <Typography sx={{ fontSize: { mobile: 20, tablet: 20, laptop: 20 }, fontWeight: "500", color: "#45BAF5" }}>Chris Hall</Typography>
                                                        <Typography sx={{ fontSize: { mobile: 14, tablet: 14, laptop: 14 }, fontWeight: "bold", color: "#E6E8EB" }}>CEO</Typography>
                                                    </Box>
                                                    <Box >
                                                        <a href="https://www.linkedin.com/in/chris-hall-b2aa894/" style={{cursor:"pointer"}} target="_blank">
                                                            <Box component="img" src={Linkedin}></Box>
                                                        </a>
                                                    </Box>

                                                </Box>
                                                <Box style={{ color: "#EFEFEF", lineHeight: 2, }} sx={{ fontSize: { mobile: 14, tablet: 14, laptop: 14 } }}>
                                                    <ul className="Ulist">
                                                        <li>Career in marketing communications with WPP, Omnicom, and Publicis Groupe(3 of the 4 largest global marketing holding firms) 17 years experience as CEO.</li>
                                                        <li>Focus: Operations, Marketing & Sales</li>
                                                    </ul>
                                                </Box>


                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item sx={{padding:"40px"}}>
                                        <Card sx={{ width: { mobile: 300, tablet: 300, laptop: 345 }, height: 540, borderRadius: 4 }}>
                                            <CardMedia
                                                component="img"
                                                height="250"
                                                image={Rothmayr}
                                                alt="name of product"
                                            />
                                            <CardContent sx={{ bgcolor: "#212529", height: 250 }}>
                                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Box>
                                                        <Typography sx={{ fontSize: { mobile: 20, tablet: 20, laptop: 20 }, fontWeight: "500", color: "#45BAF5" }}>Urs Rothmayr</Typography>
                                                        <Typography sx={{ fontSize: { mobile: 14, tablet: 14, laptop: 14 }, fontWeight: "bold", color: "#E6E8EB" }}>President - Europe</Typography>
                                                    </Box>
                                                    <Box >
                                                        <a href="https://www.linkedin.com/in/ursrothmayr/" style={{cursor:"pointer"}} target="_blank">
                                                            <Box component="img" src={Linkedin}></Box>
                                                        </a>
                                                    </Box>

                                                </Box>
                                                <Box style={{ color: "#EFEFEF", lineHeight: 2, }} sx={{ fontSize: { mobile: 14, tablet: 14, laptop: 14 } }}>
                                                    <ul className="Ulist">
                                                        <li>20+ years of experience in launching and scaling new, innovative products & services. Several successful exits.</li>
                                                        <li>Focus: European Operations & Sales</li>
                                                    </ul>
                                                </Box>


                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item sx={{padding:"40px"}}>
                                        <Card sx={{ width: { mobile: 300, tablet: 300, laptop: 345 }, height: 540, borderRadius: 4 }}>
                                            <CardMedia
                                                component="img"
                                                height="250"
                                                image={mike}
                                                alt="name of product"
                                            />
                                            <CardContent sx={{ bgcolor: "#212529", height: 250 }}>
                                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Box>
                                                        <Typography sx={{ fontSize: { mobile: 20, tablet: 20, laptop: 20 }, fontWeight: "500", color: "#45BAF5" }}>Mike Nevis</Typography>
                                                        <Typography sx={{ fontSize: { mobile: 14, tablet: 14, laptop: 14 }, fontWeight: "bold", color: "#E6E8EB" }}>COO</Typography>
                                                    </Box>
                                                    <Box >
                                                        <a href="https://www.linkedin.com/in/mnevis/" style={{cursor:"pointer"}} target="_blank">
                                                            <Box component="img" src={Linkedin} />
                                                        </a>
                                                    </Box>

                                                </Box>
                                                <Box style={{ color: "#EFEFEF", lineHeight: 2, }} sx={{ fontSize: { mobile: 14, tablet: 14, laptop: 14 } }}>
                                                    <ul className="Ulist">
                                                        <li>22 years in technology engineering and 10 years in coaching agility at team, program, portfolio, and enterprise levels. </li>
                                                        <li>Focus: Product Development, Business Operations & Agility</li>
                                                    </ul>
                                                </Box>


                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item sx={{padding:"40px"}}>
                                        <Card sx={{ width: { mobile: 300, tablet: 300, laptop: 345 }, height: 540, borderRadius: 4 }}>
                                            <CardMedia
                                                component="img"
                                                height="250"
                                                image={matt}
                                                alt="name of product"
                                            />
                                            <CardContent sx={{ bgcolor: "#212529", height: 250 }}>
                                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Box>
                                                        <Typography sx={{ fontSize: { mobile: 20, tablet: 20, laptop: 20 }, fontWeight: "500", color: "#45BAF5" }}>Matt Schachte</Typography>
                                                        <Typography sx={{ fontSize: { mobile: 14, tablet: 14, laptop: 14 }, fontWeight: "bold", color: "#E6E8EB" }}>CCO</Typography>
                                                    </Box>
                                                    <Box >
                                                        <a href="https://www.linkedin.com/in/matthewschachte3/" style={{cursor:"pointer"}} target="_blank">
                                                            <Box component="img" src={Linkedin}></Box>
                                                        </a>
                                                    </Box>

                                                </Box>
                                                <Box style={{ color: "#EFEFEF", lineHeight: 2, }} sx={{ fontSize: { mobile: 14, tablet: 14, laptop: 14 } }}>
                                                    <ul className="Ulist">
                                                        <li>25 years of experience in product brand building and customer experience. </li>
                                                        <li>Focus: Marketing & User Experience</li>
                                                    </ul>
                                                </Box>


                                            </CardContent>
                                        </Card>

                                    </Grid>
                                    <Grid item sx={{padding:"40px"}}>
                                        <Card sx={{ width: { mobile: 300, tablet: 300, laptop: 345 }, height: 540, borderRadius: 4 }}>
                                            <CardMedia
                                                component="img"
                                                height="250"
                                                image={malik}
                                                alt="name of product"
                                            />
                                            <CardContent sx={{ bgcolor: "#212529", height: 250 }} >
                                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Box>
                                                        <Typography sx={{ fontSize: { mobile: 20, tablet: 20, laptop: 20 }, fontWeight: "500", color: "#45BAF5" }}>Stephen Mallik</Typography>
                                                        <Typography sx={{ fontSize: { mobile: 14, tablet: 14, laptop: 14 }, fontWeight: "bold", color: "#E6E8EB" }}>CTO</Typography>
                                                    </Box>
                                                    <Box >
                                                        <a href="https://www.linkedin.com/in/stephen-mallik-b913b55/" style={{cursor:"pointer"}} target="_blank">
                                                            <Box component="img" src={Linkedin}></Box>
                                                        </a>
                                                    </Box>

                                                </Box>
                                                <Box style={{ color: "#EFEFEF", lineHeight: 2, }} sx={{ fontSize: { mobile: 14, tablet: 14, laptop: 14 } }}>
                                                    <ul className="Ulist">
                                                        <li >20 years in Software Technology and 10 years as an entrepreneur.</li>

                                                        <li >Focus: Product vision, Technology & Intellectual Property development</li>
                                                    </ul>
                                                </Box>

                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                    <Grid id="contactUs" sx={{padding: "80px 22px" }}>
                        <Box sx={{maxWidth:{mobile:"980px",desktop:"1100px",desktopLg:"1400px"}, marginLeft:"auto", marginRight: "auto"}}>
                            <Grid container sx={{ display: "flex", justifyContent: "center" }}>
                                <Grid item mobile={12} tablet={6} laptop={6} sx={{paddingRight:{laptop:5}}}>
                                <motion.div initial='initial' whileInView="animate" variants={title_ani}>
                                    <Box sx={{ display: "flex", justifyContent:{mobile:"center",tablet:"initial"} }}>
                                        {/* <Typography className="gradient" sx={{ fontSize: { mobile: 24, tablet: 30, laptop: 36 }, fontWeight: "bold", textDecoration: "2px", textAlign: { mobile: "center", tablet: "center", laptop: "left" } }}>Contact Us</Typography> */}
                                        <Typography className="gradient" sx={{ fontSize: { mobile: 24, tablet: 30, laptop: 36 }, textAlign: {mobile:"center",tablet:"initial"}, letterSpacing:-1, fontWeight: "bold", borderBottom: "2px solid #4B4B4B" }}>Contact Us</Typography>

                                        {/* <Typography sx={{ fontSize: { mobile: 16, tablet: 20, laptop: 24 }, color: "#E6E8EB", letterSpacing: { mobile: 2, tablet: 5, laptop: 10 } }}>OUR UNIQUE</Typography> */}
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent:{mobile:"center",tablet:"initial"}, paddingTop: "24px" }}>
                                        <Typography sx={{ fontSize: { mobile: 16, tablet: 20, laptop: 22 }, textAlign: {mobile:"center", tablet: "initial"}, color: "#EFEFEF" }}>We love quesion and feedback. Here are some ways to contact us. </Typography>
                                    </Box>
                                </motion.div>
                                </Grid>
                                <Grid item mobile={12} tablet={6} laptop={6}
                                    sx={{bgcolor: "#212529", borderRadius: 8,marginTop: 3}} >
                                    <Messageform />
                                </Grid>                           
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid sx={{ paddingTop: {mobile:5,tablet:10},background:"#111111", paddingBottom: 3 }}>
                        <Box id="menuHeader" sx={{ maxWidth:{mobile:"980px",desktop:"1100px",desktopLg:"1400px"}, padding:"0px 22px", display:"flex",flexFlow: {mobile:"column-reverse",tablet:"nowrap"}, flexDirection:"row", marginLeft: "auto", marginRight: "auto",justifyContent:"space-between",marginBottom:3 }}>
                            {/* <Grid container>
                                <Grid item mobile={4} tablet={4}> */}
                                    <Box sx={{marginTop:{mobile:3,tablet:0}}}>
                                        <Box component="img" sx={{ width: {mobile:"80px",tablet:"90px",laptop:"90px",desktop:"110px"} }} src={logo} />
                                        <Box sx={{marginTop: 1}}>
                                            {/* <Box component="img" sx={{ margin: "8px 8px 8px 0px" }} src={Facebook_footer} /> */}
                                            {/* <Box component="img" sx={{ margin: 1 }} src={twitter} /> */}
                                            <a href="https://www.linkedin.com/company/arrivu/" style={{cursor:"pointer"}} target="_blank">
                                                <Box component="img" src={linkedin_footer} sx={{width:20}} />
                                            </a>
                                            {/* <Box component="img" sx={{ margin: 1 }} src={Youtube} /> */}
                                        </Box>
                                    </Box>
                                {/* </Grid>
                                <Grid item mobile={8} tablet={8}> */}
                                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                        <Box>
                                            <Box>
                                                <Typography sx={{ color: "white", fontWeight: "bold", fontSize: { mobile: 12, tablet: 14, laptop: 14 }, paddingBottom: 2 }}>News Letter</Typography></Box>
                                            <Box sx={{ display: "flex", paddingBottom: 2 }}>
                                                <Box component="img" src={mail} />
                                                <Typography sx={{ color: "white", fontSize: { mobile: 12, tablet: 16, laptop: 14, paddingLeft: 14, } }}>Stay up to date on the latest from Arrivu</Typography>
                                            </Box>
                                            <form>
                                                <InputBase type="text" placeholder='Enter Your Email' onChange={(e) => setSubscribe(e.target.value)} id='getFile' sx={{ width: { mobile: 200, tablet: 150, laptop: 250 }, height: 40, color: "white", border: "1px white solid", paddingLeft: 2, borderRadius: .5, fontSize: { mobile: 10, tablet: 14, laptop: 16 }, marginBottom: 2 }} multiline />
                                                <Box>
                                                    <Button style={{}} variant="contained" sx={{ bgcolor: "#0057B8",fontSize: { mobile: 12, tablet: 14, laptop: 14 }, paddingBottom: 1 }} onClick={handleSubmit}>Subscribe</Button>
                                                </Box>
                                            </form>
                                        </Box>

                                    </Box>
                                {/* </Grid>
                            </Grid> */}
                        </Box>
                        <Box sx={{maxWidth:{mobile:"980px",desktop:"1100px",desktopLg:"1400px"},padding:"0px 22px", marginLeft: "auto", marginRight: "auto"}}>
                            <Typography sx={{ color: "#FFFFFF", fontWeight: "400", fontSize: { mobile: "10px", tablet: "12px", laptop: "12px", }, display: "flex", alignItems: "center", textDecoration: "none" }} ><CopyrightIcon sx={{ fontSize: { mobile: "10px", tablet: "12px", laptop: "12px", }, marginRight: 1 }} /> 2022 Arrivu Technologies Inc-All Rights Reserved </Typography>
                            {/* <Typography sx={{ color: "#FFFFFF", fontWeight: "700", fontSize: { xs: "10px", sm: "12px", md: "14px", },display:"flex",textDecoration:"none"}} ><CopyrightIcon sx={{fontSize: { xs: "10px", sm: "12px", md: "14px", },marginRight:1}}/> 2022 Arrivu Technologies Inc-All Rights Reserved </Typography> */}
                        </Box>
                    </Grid>      
                </Box>
            </ThemeProvider>


        </>

    )
}
export default Home