import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Grid, Typography, Input, InputLabel, FormControl, InputBase, Button, Paper, IconButton, } from "@mui/material"
import { makeStyles } from "@mui/material/styles"
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {useState} from "react"
import axios from "axios"
// import styled from "@emotion/styled"
// const useStyles=makeStyles(theme=>({
//     inputbase:{
//         border:"2px solid white"
//     }

// }))

function Messageform() {
    // const classes=useStyles()
    const [attachment,setAttachment]=useState([{name:"Attachment"}])
    const [name,setName]=useState("")
    const [email,setEmail]=useState("")
    const [message,setMessage]=useState("")
    const [subject,setSubject]=useState("")
    const getFile = () => {
        document.getElementById("getFile").click()
      

      
    }
    const handleSubmit=(e)=>{
        e.preventDefault()
        axios.post('https://p03c30hata.execute-api.us-east-2.amazonaws.com/dev-arrivu/contact-us', {
            message: message,    
            firstName: name,
            subject: subject,
            email: email
          })
          .then(function (response) {
            //console.log("respons from the api",response);
          })
          .catch(function (error) {
            //console.log("in error",error);
          });
        console.log(name)
        console.log(email)
        console.log(message)
        console.log(subject)
        console.log(attachment)
       
    }
    // const value= document.getElementById("getFile").value
    const handleChange=(e)=>{
        setAttachment(e.target.files)
        for (let i = 0; i < attachment.length; i++) {
            console.log(attachment)
        }

       
    }
  

    return (
        <Grid container sx={{ placeItems: "center", placeContent:"center",  }}>
            <Grid container sx={{ display: "flex",  placeItems: "start", justifyContent: "flex-start",padding: 2 }}>
                <Grid item sx={{ width: 700 }}>
                    <Typography sx={{ color: "white", fontSize: {mobile:14,laptop:24,tablet:16} }}> Send us a message</Typography>
                </Grid>
                <Grid item>
                    <Typography sx={{ color: "white", fontSize: {mobile:10,tablet:12,laptop:14}, paddingtop: 5, }}>Send your message and we will respond with in 24 hours</Typography>
                </Grid>

            </Grid>
            <form style={{}}>
                <Grid container sx={{  }}>
                    <InputBase type="text" placeholder='Name' onChange={(e)=>setName(e.target.value)}sx={{ width: "inherit", height: 40, color: 'grey', border: "1px white solid", bgcolor: "white", paddingLeft: 2, borderRadius: 2, margin: "10px 16px" }} />
                    <InputBase type="text" placeholder='Email Address'onChange={(e)=>setEmail(e.target.value)} sx={{ width:"inherit", height: 40, color: 'grey', border: "1px white solid", bgcolor: "white", paddingLeft: 2, borderRadius: 2, margin: "10px 16px" }} />
                    <InputBase type="text" placeholder='Subject'onChange={(e)=>setSubject(e.target.value)} sx={{ width:"inherit", height: 40, color: 'grey', border: "1px white solid", bgcolor: "white", paddingLeft: 2, borderRadius: 2, margin: "10px 16px" }} />
                    <InputBase type="text" placeholder='Text Your Message 'onChange={(e)=>setMessage(e.target.value)} sx={{ width:"inherit", height: 120, color: 'grey', border: "1px white solid", bgcolor: "white", paddingLeft: 2, borderRadius: 2, margin: "10px 16px" }} multiline  rows={4}/>
                    {/* <InputBase type="file" placeholder='Attachment '  onChange={handleChange}  id='getFile' sx={{ width: {mobile:250,tablet:400,laptop:700}, height: 40, color: 'grey', border: "1px white solid", bgcolor: "white", paddingLeft: 2, borderRadius: 2, margin: 2, display: "none" }} startAdornment={<AttachFileIcon onClick="document.getElementById('getFile').click()" />} />
                    <Paper sx={{margin:2,width:{mobile:250,tablet:400,laptop:700},borderRadius:2}}>
                    <IconButton sx={{}} aria-label="menu">
                        <AttachFileIcon onClick={getFile} />
                        <Typography sx={{paddingLeft:2}}>{attachment[0].name}</Typography>
                    </IconButton>
                    </Paper> */}


                </Grid>
                <Grid container sx={{ padding: "0px 0px 24px 16px",marginTop:"10px" }}>
                    <Button  variant="contained"  sx={{bgcolor:"#0057B8", width:"132px"}} onClick={handleSubmit}>Submit</Button>
                </Grid>

            </form>
        </Grid>
    )
}
export default Messageform